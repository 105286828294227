import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-nice-dates/build/style.css';
import "./scss/user.scss";
import {
  // BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"

import LandingPage from './views/LandingPage/LandingPage';
import LoginPage from './views/LoginPage/LoginPage';
import RegisterPage from './views/RegisterPage/RegisterPage';
import RegisterMemberPage from './views/RegisterPage/RegisterMemberPage';
import RegisterCompanyPage from './views/RegisterPage/RegisterCompanyPage';
import RegisterCompletePage from './views/RegisterPage/RegisterCompletePage';
import NoticePage from './views/NoticePage/NoticePage';
import ChargerReceptionPage from './views/ChargerReceptionPage/ChargerReceptionPage';
import NoticeDetail from './views/NoticePage/NoticeDetail';
import StipulationPage from './views/StipulationPage/StipulationPage';
import Me from './views/Mypage/Me';
import Password from './views/Mypage/Password';
import Paycard from './views/Mypage/Paycard';
import Charging from './views/Mypage/Charging';
import Close from './views/Mypage/Close';
import QRpayDetail from './views/QRPage/QRpayDetailPage';
import QRpayPage from './views/QRPage/QRpayPage';
import QRpayChargingInfoPage from './views/QRPage/QRpayChargingInfoPage';
import QRpayPaymentPage from './views/QRPage/QRpayPaymentPage';
import QRpayResultPage from './views/QRPage/QRpayResultPage';
import SubsidyPage from './views/SubsidyPage/SubsidyPage';
import ChargerReceptionCompletePage from './views/ChargerReceptionPage/ChargerReceptionCompletePage';
import PrivateRoute from './middleware/auth/PrivateRoute';
import NotFound from './components/Error/NotFound';

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      {/* <Route path="/register" element={<RegisterPage />} />
      <Route path="/register/member" element={<RegisterMemberPage />} />
      <Route path="/register/company" element={<RegisterCompanyPage />} /> */}
      <Route path="/register/complete" element={<RegisterCompletePage />} />
      <Route path="/notice" element={<NoticePage />} />
      <Route path="/notice/:id" element={<NoticeDetail />} />
      <Route path="/receptions" element={<ChargerReceptionPage />} />
      <Route path="/receptions/complete" element={<ChargerReceptionCompletePage />} />
      <Route path="/policy" element={<StipulationPage ty="policy" />} />
      <Route path="/privacy" element={<StipulationPage ty="privacy" />} />
      <Route path="/me" element={
        <PrivateRoute>
          <Me />
        </PrivateRoute>
      } />
      <Route path="/password" element={
        <PrivateRoute>
          <Password />
        </PrivateRoute>
      } />
      <Route path="/paycard" element={
        <PrivateRoute>
          <Paycard />
        </PrivateRoute>
      } />
      <Route path="/charging" element={
        <PrivateRoute>
          <Charging />
        </PrivateRoute>
      } />
      <Route path="/close" element={
        <PrivateRoute>
          <Close />
        </PrivateRoute>
      } />
      <Route path="/QRpay" element={<QRpayPage />} caseSensitive />
      <Route path="/QRpay/:qrcode" element={<QRpayDetail />} caseSensitive />
      <Route path="/QRpay/:qrcode/charging_info" element={<QRpayChargingInfoPage />} caseSensitive />
      <Route path="/QRpay/:qrcode/charging_info/payment" element={<QRpayPaymentPage />} caseSensitive />
      <Route path="/QRpay/:qrcode/payment_result" element={<QRpayResultPage />} caseSensitive />
      <Route path="/subsidy" element={<SubsidyPage />} />
      <Route path={"*"} element={<NotFound />} />
    </Routes>
  );
}

export default App;
